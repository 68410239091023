import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from './config-community'; // Import config for videoRoomId
import './Call.css'; // Import custom CSS file

function Call() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    // Extract the full visit_id as the "code" parameter
    const visit_id = `code=${params.get('code')}`;

    // Get the timestamp from the first 13 digits of the "code"
    const timestampParam = Number(params.get('code').substring(0, 13));

    const currentTime = new Date().getTime();
    const oneHourLater = timestampParam + 400000; // Adjust the time limit
    const diff = oneHourLater - currentTime;

    // Function to handle the button clicks and post to the API
    const handleAuthPost = async (authLevel, buttonText) => {
        try {
            const response = await axios.post(config.linenotify, {
                command: "authpass",
                auth_level: authLevel, // Pass the selected authorization level
                visit_id: visit_id, // Include visit_id in the request body
                button_text: buttonText // Include button text in the request body
            });

            // Handle the response
            console.log('API Response:', response.data);
        } catch (error) {
            console.error('API Error:', error);
        }
    };

    useEffect(() => {
        const idleTimer = setTimeout(() => {
            window.location.reload(); // Refresh the page after idle
        }, 400000);

        return () => clearTimeout(idleTimer);
    }, []);

    if (timestampParam && diff >= 0) {
        return (
            <div>
                <iframe
                    className="call-iframe"
                    src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                    allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                    title="datavan"
                ></iframe>

                <div className="button-section">
                    <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>授權訪客通行證(15分鐘有效)</h3>
                    <div className="button-container">
                        {/* Button to access the main door */}
                        <button
                            className="btn btn-primary"
                            onClick={() => handleAuthPost('main_door', '進大門到大廳')}
                        >
                            進大門到大廳
                        </button>

                        {/* Button to access the building's first floor */}
                        <button
                            className="btn btn-secondary"
                            onClick={() => handleAuthPost('first_floor', '可到本棟一樓')}
                        >
                            可到本棟一樓
                        </button>

                        {/* Button to access user's floor */}
                        <button
                            className="btn btn-success"
                            onClick={() => handleAuthPost('user_floor', '可到住戶樓層')}
                        >
                            可到住戶樓層
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div>Expired or invalid link.</div>;
    }
}

export default Call;
