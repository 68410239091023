import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaMicrophone, FaSyncAlt, FaPlay, FaIdCard } from 'react-icons/fa';
import { RiSpeakFill } from 'react-icons/ri';
import config from './config-community';
import Room from './Room'; // Import Room component
import './Help.css';
import Ocr from './Ocr'; // Import Ocr component
import Contacts from './Contacts'; // Make sure the path is correct
// import Record from './Record'; // Import Record component

function Help() {
    const [messages, setMessages] = useState([
        { role: 'assistant', content: '歡迎光臨鴻翊大觀社區！請按下麥克風，告訴我您的姓名與單位及拜訪目的。我將協助您連繫您要找的人' },
    ]);
    const [input, setInput] = useState('');
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [roomID, setRoomID] = useState(null);
    const [loading, setLoading] = useState(false); // State to track loading status
    const [responseData] = useState(null); // State to store the AI response data
    const chatBoxRef = useRef(null);
    const audioRef = useRef(null);
    const blipSound = useMemo(() => new Audio('/blip.mp3'), []);
    const doneSound = useMemo(() => new Audio('/done.mp3'), []);
    const stopSound = useMemo(() => new Audio('/stop.mp3'), []);
    const ringSound = useMemo(() => new Audio('/ring.mp3'), []);
    // const audioRef = useRef(new Audio('/ring.mp3')); // Ref to store the audio object

    const cameraStreamRef1 = useRef(null);
    const cameraStreamRef2 = useRef(null);
    const [showRecordModal, setShowRecordModal] = useState(false);
    const [showPeopleOptionsModal, setShowPeopleOptionsModal] = useState(false);
    const [transcription, setTranscription] = useState('');
    const [ttsStatus, setTtsStatus] = useState('silence'); // State to track TTS status
    const [showInputSection] = useState(false); // Change to 'true' if you want it visible by default
    const [isMicDisabled, setIsMicDisabled] = useState(false);
    const [showStartModal, setShowStartModal] = useState(true); // State to control the visibility of the start modal
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // State to manage loading effect
    // const [, setIsDepartmentLoading] = useState(false); // New state for department loading
    // const onDutyPerson = config.residents.find(contact => contact.on_duty);

    // const [ setStartIndex] = useState(0); // Track start index for cycling departments
    const [showCallModal, setShowCallModal] = useState(false); // Modal state
    const [timer, setTimer] = useState(300); // 5 minutes in seconds (300 seconds)

    // State for idle timer
    const [idleTime, setIdleTime] = useState(0);
    const [showOcrModal, setShowOcrModal] = useState(false);

    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const sourceRef = useRef(null);
    const [microphoneAccessError, setMicrophoneAccessError] = useState(false);
    const [isTranscriptionSent, setIsTranscriptionSent] = useState(false);
    const [confirmedUserName, setConfirmedUserName] = useState(''); // New state to store the confirmed user name

    const [userProfile, setUserProfile] = useState({
        user_name: '',
        user_company: ''
    });

    // Tooltip styles
    const tooltipStyles1 = {
        position: 'absolute',
        top: '-60px', // Position above the element
        left: '50%',
        padding: '12px 20px',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#fff',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #ff6f61, #d45079)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        animation: 'flash 1.5s infinite', // Flashing animation
        animationTimingFunction: 'ease-in-out',
        transform: 'translate(-50%, -100%)', // Center above the target
    };

    // Arrow styles for the first tooltip
    const arrowStyles1 = {
        position: 'absolute',
        top: '100%', // Position the arrow below the tooltip
        left: '90%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #ff6f61',
        filter: 'drop-shadow(0 0 8px rgba(255, 111, 97, 0.8))', // Glow effect
    };

    // Styles for the second tooltip with a purple-based gradient
    // const tooltipStyles2 = {
    //     position: 'absolute',
    //     top: '-0px', // Position above the element
    //     left: '50%',
    //     padding: '12px 20px',
    //     borderRadius: '8px',
    //     fontSize: '14px',
    //     color: '#fff',
    //     whiteSpace: 'nowrap',
    //     zIndex: 1000,
    //     display: 'flex',
    //     alignItems: 'center',
    //     background: 'linear-gradient(135deg, #a55eea, #8854d0)', // Purple-based gradient
    //     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    //     animation: 'flash 1.5s infinite', // Flashing animation
    //     animationTimingFunction: 'ease-in-out',
    //     transform: 'translate(-50%, -100%)', // Center above the target
    // };

    // Arrow styles for the second tooltip
    // const arrowStyles2 = {
    //     position: 'absolute',
    //     top: '100%', // Position the arrow below the tooltip
    //     left: '70%',
    //     transform: 'translateX(-50%)',
    //     width: '0',
    //     height: '0',
    //     borderLeft: '10px solid transparent',
    //     borderRight: '10px solid transparent',
    //     borderTop: '10px solid #a55eea', // Match the gradient color
    //     filter: 'drop-shadow(0 0 8px rgba(165, 94, 234, 0.8))', // Glow effect
    // };


    // // Styles for the third tooltip with a green-based gradient
    // const tooltipStyles3 = {
    //     position: 'absolute',
    //     top: '-0px', // Position above the element
    //     left: '50%',
    //     padding: '12px 20px',
    //     borderRadius: '8px',
    //     fontSize: '14px',
    //     color: '#fff',
    //     whiteSpace: 'nowrap',
    //     zIndex: 1000,
    //     display: 'flex',
    //     alignItems: 'center',
    //     background: 'linear-gradient(135deg, #28a745, #218838)', // Green-based gradient
    //     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    //     animation: 'flash 1.5s infinite', // Flashing animation
    //     animationTimingFunction: 'ease-in-out',
    //     transform: 'translate(-50%, -100%)', // Center above the target
    // };

    // // Arrow styles for the third tooltip
    // const arrowStyles3 = {
    //     position: 'absolute',
    //     top: '100%', // Position the arrow below the tooltip
    //     left: '70%',
    //     transform: 'translateX(-50%)',
    //     width: '0',
    //     height: '0',
    //     borderLeft: '10px solid transparent',
    //     borderRight: '10px solid transparent',
    //     borderTop: '10px solid #28a745', // Match the gradient color
    //     filter: 'drop-shadow(0 0 8px rgba(40, 167, 69, 0.8))', // Glow effect
    // };


    const highlightStyles = {
        position: 'absolute',
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        opacity: 1,
    };

    // const highlightStyles2 = {
    //     position: 'relative',
    //     transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    //     opacity: 1,
    // };

    // Function to handle user activity and reset idle timer
    const resetIdleTimer = useCallback(() => {
        setIdleTime(0); // Reset the idle timer
    }, []);



    const visualize = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');

        const renderFrame = () => {
            if (!analyserRef.current || !dataArrayRef.current) return;

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            canvasContext.fillStyle = 'rgb(200, 200, 200)';
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            canvasContext.lineWidth = 2;
            canvasContext.strokeStyle = 'rgb(0, 0, 0)';
            canvasContext.beginPath();

            const sliceWidth = (canvas.width * 1.0) / analyserRef.current.frequencyBinCount;
            let x = 0;

            for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
                const v = dataArrayRef.current[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    canvasContext.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();

            requestAnimationFrame(renderFrame);
        };

        renderFrame();
    };


    const sendToOpenAI = useCallback(async (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.webm');
        formData.append('model', 'whisper-1');
        // formData.append('language', 'zh-TW');

        try {
            const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                headers: {
                    'Authorization': `Bearer ${config.openAiApiKey}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const transcription = response.data.text;
            onTranscription(transcription);
        } catch (error) {
            console.error('Error sending audio to OpenAI:', error);
        }
    }, []);

    const onTranscription = (transcription) => {
        setTranscription(transcription);
        // Optionally, handle the transcription further if needed
    };


    const onClose = () => {
        setMicrophoneAccessError(false);
    };



    const startMicrophoneSetup = async () => {
        try {

            // Call handleStop to ensure all previous streams and processes are stopped
            handleStop();


            // Create a new AudioContext if not already created
            if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                console.log('AudioContext created successfully @ startMicrophoneSetup.');
            }

            // Ensure the AudioContext is in a running state
            if (audioContextRef.current.state === 'suspended') {
                await audioContextRef.current.resume();
                console.log('AudioContext resumed.');
            }

            // Request microphone access, start recording
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log('Microphone access granted.');
            microphoneRef.current = stream;

            // Ensure audioContextRef.current is initialized
            if (!audioContextRef.current) {
                console.error('AudioContext is not initialized.');
                setMicrophoneAccessError(true);
                return;
            }

            sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
            console.log('MediaStreamSource created successfully.');
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 2048;
            sourceRef.current.connect(analyserRef.current);

            dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
            visualize();

            const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            mediaRecorderRef.current = mediaRecorder;
            console.log('MediaRecorder initialized successfully.');
            mediaRecorder.start();
            console.log('MediaRecorder started.');

            mediaRecorder.ondataavailable = (event) => {
                console.log('MediaRecorder data available.');
                audioChunks.current.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                console.log('MediaRecorder stopped.');
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                audioChunks.current = [];
                await sendToOpenAI(audioBlob);
            };

            mediaRecorder.onerror = (error) => {
                console.error('MediaRecorder error:', error);
            };
        } catch (error) {
            console.error('Error during microphone setup:', error);
            setMicrophoneAccessError(true);
        }
    };


    const handleMicrophoneClick = async () => {
        await startMicrophoneSetup();
        startListening(); // Use the startListening function here
    };


    // Cleanup function to stop all media streams and reset recorder
    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current) {
                console.log('Stopping MediaRecorder.');
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
                mediaRecorderRef.current = null;
            }
            if (microphoneRef.current) {
                console.log('Stopping microphone stream.');
                microphoneRef.current.getTracks().forEach(track => track.stop());
                microphoneRef.current = null;
            }
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                console.log('Closing AudioContext.');
                audioContextRef.current.close().then(() => {
                    audioContextRef.current = null;
                });
            }
        };
    }, []);



    useEffect(() => {
        // Increment idle time only if showCallModal is not active
        if (!showCallModal) {
            const interval = setInterval(() => {
                setIdleTime((prevIdleTime) => prevIdleTime + 1);
            }, 1000); // Increment idle time every second

            // Reload the page if idle time exceeds 400 seconds
            if (idleTime >= 120) {
                window.location.reload(); // Reload the page
            }

            // Add event listeners to reset idle timer on user activity
            window.addEventListener('mousemove', resetIdleTimer);
            window.addEventListener('keypress', resetIdleTimer);
            window.addEventListener('touchstart', resetIdleTimer);
            window.addEventListener('click', resetIdleTimer);

            return () => {
                clearInterval(interval);
                window.removeEventListener('mousemove', resetIdleTimer);
                window.removeEventListener('keypress', resetIdleTimer);
                window.removeEventListener('touchstart', resetIdleTimer);
                window.removeEventListener('click', resetIdleTimer);
            };
        }
    }, [idleTime, resetIdleTimer, showCallModal]);




    const toggleCallModal = () => {
        console.log("Toggling Modal: Current State:", showCallModal);

        // Play the audio
        doneSound.play().catch(err => console.error('Error playing audio:', err));

        // Check if the modal is being closed
        if (showCallModal) {
            // If the modal is currently open, and this action is closing it
            doneSound.onended = () => {
                window.location.reload(); // Reload the page after the sound has played
            };
        } else {
            // If the modal is being opened, simply toggle it without reloading
            setShowCallModal(prevState => !prevState);
            console.log("Toggled Modal: New State:", !showCallModal); // Log the new state after toggling
            setTimer(300); // Reset the timer to 5 minutes when the modal opens
        }
    };

    // Countdown effect
    useEffect(() => {
        let countdownInterval;
        if (showCallModal && timer > 0) {
            countdownInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1); // Decrease the timer every second
            }, 1000);
        } else if (timer === 0) {
            // Close modal when timer reaches 0
            setShowCallModal(false);
            window.location.reload(); // Reload page after closing the modal
        }

        return () => clearInterval(countdownInterval); // Cleanup interval on unmount
    }, [showCallModal, timer]);



    const handleStop = () => {
        console.log('handleStop called. Initial states:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        // Cancel any ongoing API requests
        axios.CancelToken.source().cancel('Operation canceled by the user.');

        // Stop camera capture for both streams
        if (cameraStreamRef1.current) {
            const tracks = cameraStreamRef1.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef1.current = null;
            console.log('Camera stream 1 stopped.');
        } else {
            console.log('Camera stream 1 was not active.');
        }

        if (cameraStreamRef2.current) {
            const tracks = cameraStreamRef2.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef2.current = null;
            console.log('Camera stream 2 stopped.');
        } else {
            console.log('Camera stream 2 was not active.');
        }

        // Use refs to access video elements and stop them
        const videoRef1 = document.getElementById('cameraVideo1');
        const videoRef2 = document.getElementById('cameraVideo2');

        if (videoRef1 && videoRef1.srcObject) {
            const stream = videoRef1.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef1.srcObject = null;  // Clear the video source
            videoRef1.pause();  // Pause the video playback
            console.log('Video preview 1 stopped and source cleared.');
        } else {
            console.log('Video preview 1 was not active or has no stream.');
        }

        if (videoRef2 && videoRef2.srcObject) {
            const stream = videoRef2.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef2.srcObject = null;  // Clear the video source
            videoRef2.pause();  // Pause the video playback
            console.log('Video preview 2 stopped and source cleared.');
        } else {
            console.log('Video preview 2 was not active or has no stream.');
        }

        // Ensure the camera and microphone streams from Record.js are stopped
        if (microphoneRef.current) {
            microphoneRef.current.getTracks().forEach(track => track.stop());
            microphoneRef.current = null;
            console.log('Microphone stream stopped.');
        }

        // Stop microphone listening
        if (isListening) {
            setIsListening(false);
            console.log('Microphone listening stopped.');
        } else {
            console.log('Microphone was not listening.');
        }

        // Check and log TTS status
        console.log('Current TTS status before stopping audio:', ttsStatus);
        if (ttsStatus === 'speaking') {
            console.log('TTS is currently speaking. Preparing to stop.');
        } else {
            console.log('TTS is not speaking.');
        }

        // Stop audio playback
        if (audioRef.current) {
            audioRef.current.pause();  // Pause the audio
            audioRef.current.currentTime = 0;  // Reset the audio playback time to the beginning
            audioRef.current = null;  // Clear the audio reference
            console.log('Audio playback stopped.');

            // Explicitly set TTS status to "silence"
            setTtsStatus('silence');
            console.log('TTS status set to "silence".');
        } else {
            console.log('No audio was playing.');
        }

        // Reset any ongoing state or processes
        setLoading(false);
        setPeopleOptions([]);
        setShowModal(false);
        setShowRecordModal(false);
        setShowPeopleOptionsModal(false);
        setTranscription('')

        // Enable the microphone button after all processes have been stopped
        setIsMicDisabled(false);
        console.log('Microphone button enabled.');

        // Debugging the state after all actions
        console.log('Final states after handleStop execution:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        console.log('All processes stopped and microphone re-enabled.');
    };



    const handleStart = () => {
        // Close the start modal
        setShowStartModal(false);

        // Create and play the pre-recorded welcome message
        // audioRef.current = new Audio('/welcome.mp3');
        // audioRef.current = new Audio('/say_your_name.mp3');
        audioRef.current = new Audio('/com-greeting.mp3');
        audioRef.current.play();
    };


    // Function to handle text-to-speech
    const handleTextToSpeech = useCallback(async (text) => {
        try {
            // Stop and reset current audio if it's already playing
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                console.log('Existing audio stopped and reset.');
                audioRef.current = null;  // Clear previous reference
            }

            // Call API to get TTS audio
            const response = await axios.post(
                'https://api.openai.com/v1/audio/speech',
                {
                    model: 'tts-1',
                    input: text,
                    voice: 'nova',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                    responseType: 'blob',
                }
            );

            // Create new audio element from response
            const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
            const audio = new Audio(audioUrl);
            audioRef.current = audio;

            // Debugging: Check if audio element is created
            console.log('New audio element created:', audio);

            // Set TTS status to "speaking" when audio starts
            audio.onplay = () => {
                setTtsStatus('speaking');
                console.log('TTS started speaking.');
            };

            // Set TTS status to "silence" when audio ends
            audio.onended = () => {
                setTtsStatus('silence');
                audioRef.current = null;  // Clear the audio reference when finished
                console.log('TTS finished speaking and status set to "silence".');
            };

            // Play the audio
            audio.play().catch((err) => console.error('Error playing audio:', err));

            // Debugging: Confirm the play action is initiated
            console.log('Audio play action initiated.');
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    }, []);

    useEffect(() => {
        if (peopleOptions.length > 0) {
            setIsLoading(true); // Show loading effect
            setTimeout(() => {
                setIsLoading(false); // Hide loading effect after 1 second
                setShowPeopleOptionsModal(true); // Show the modal
            }, 2000); // Delay for 1 second (1000 milliseconds)
        } else {
            setShowPeopleOptionsModal(false);
        }
    }, [peopleOptions]);

    useEffect(() => {
        const startCameraStream = (ref, videoElementId) => {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    ref.current = stream;
                    const videoElement = document.getElementById(videoElementId);
                    if (videoElement) {
                        videoElement.srcObject = stream;
                        videoElement.play();
                    }
                })
                .catch((error) => {
                    console.error(`Error accessing the camera for ${videoElementId}:`, error);
                });
        };

        if (showPeopleOptionsModal) {
            startCameraStream(cameraStreamRef1, 'cameraVideo1');
        } else if (showModal) {
            startCameraStream(cameraStreamRef2, 'cameraVideo2');
        } else {
            handleCloseModal(cameraStreamRef1);
            handleCloseModal(cameraStreamRef2);
        }
    }, [showPeopleOptionsModal, showModal]);

    const handleCloseModal = (cameraRef) => {
        setShowModal(false);
        setShowPeopleOptionsModal(false);
        if (cameraRef.current) {
            const tracks = cameraRef.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraRef.current = null;
        }
    };

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);




    const handleSendMessage = useCallback(async (text = input) => {
        if (!text.trim()) return;

        const userMessage = { role: 'user', content: text };
        const updatedMessages = [...messages, userMessage];
        console.log('updatedMessages===>', updatedMessages);
        console.log(' updatedMessages', JSON.stringify(updatedMessages))

        setMessages(updatedMessages);
        setInput('');
        setIsMicDisabled(true);  // Disable the microphone button

        try {

            // Get only the last message from the user
            const lastUserMessage = updatedMessages[updatedMessages.length - 1];

            console.log(' lastUserMessage', JSON.stringify(lastUserMessage))

            if (!confirmedUserName) {
                // Logic when confirmedUserName is false
                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-4o-mini',
                        messages: [
                            {
                                role: 'system',
                                content: `你是鴻翊大觀社區的安全助手，負責確認來訪者的基本信息及來訪目的。你的任務是詢問使用者的姓名、公司（如果適用），以及來訪的原因。請禮貌且清楚地引導使用者提供完整的資訊。當詢問時，請仔細區分來訪者的姓名是否與他們所要拜訪的住戶不同。如果使用者表示「我找某某先生/女士」，這表明他在尋找某人，而非報告他自己的姓名。請禮貌地澄清使用者的姓名和來訪原因。如果使用者沒有提供所有必要的訊息，請耐心地繼續詢問，直到獲得以下完整的資料：
                        1. 來訪者的姓名
                        2. 來訪者的公司名稱（如果適用，則為可選項）
                        3. 來訪的原因或目的
                        當你收集到所有資訊後，請用以下的 JSON 格式回應：`
                            },
                            ...updatedMessages,
                        ],
                        response_format: {
                            type: 'json_schema',
                            json_schema: {
                                name: 'visitor_info_response',
                                schema: {
                                    type: 'object',
                                    properties: {
                                        user_name: {
                                            type: 'string',
                                            description: '來訪者的姓名',
                                        },
                                        user_company: {
                                            type: 'string',
                                            description: '來訪者的公司名稱（如果有）',
                                        },
                                        visit_purpose: {
                                            type: 'string',
                                            description: '來訪的原因或目的',
                                        },
                                        message: {
                                            type: 'string',
                                            description: '要求進一步訊息的回應訊息',
                                        },
                                        is_confirmed: {
                                            type: 'boolean',
                                            description: '是否已確認來訪者姓名及來訪目的',
                                        }
                                    },
                                    required: ['user_name', 'user_company', 'visit_purpose', 'message', 'is_confirmed'],
                                    additionalProperties: false,
                                },
                                strict: true,
                            },
                        },
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${config.openAiApiKey}`,
                        },
                    }
                );



                const structuredResponse = JSON.parse(response.data.choices[0].message.content);
                const { user_name, user_company, visit_purpose, message: responseMessage, is_confirmed } = structuredResponse;

                console.log('structuredResponse', structuredResponse)

                let message;
                if (user_name && is_confirmed) {
                    // Set confirmed user name, company, and purpose in state
                    setUserProfile(prevProfile => ({
                        ...prevProfile,
                        user_name,
                        user_company: user_company || prevProfile.user_company,
                        visit_purpose,
                    }));
                    setConfirmedUserName(user_name);

                    // Prepare the appropriate message
                    message = user_company
                        ? `您好，${user_name}，來自 ${user_company}。您要拜訪的原因是 ${visit_purpose}。請按麥克風繼續搜尋或按右邊快捷鍵通話。`
                        : `您好，${user_name}。您的拜訪目的為 ${visit_purpose}。請按麥克風繼續搜尋或按右邊快捷鍵通話。`;
                } else {
                    message = responseMessage;
                }

                setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: message }]);
                await handleTextToSpeech(message);
            }

            else {

                // Logic when confirmedUserName is true
                const postData = {
                    model: 'gpt-4o-mini',
                    messages: [
                        {
                            role: 'system',
                            content: `您是小區的安全助手，負責協助使用者根據提供的建築物、樓層、房間號碼或完整地址查找住戶。以下是小區住戶的聯絡名單：${config.residents.map(resident => `${resident.棟}棟 ${resident.樓}樓 ${resident.室}室 ${resident.名字} ${resident.姓}，住址號碼：${resident.住址號碼}，完整地址：${resident.住址號碼} ${resident.樓}樓 ${resident.室}室，手機號碼：${resident.手機}`).join('，')}。當使用者查詢住戶時，請根據他們提供的建築物、樓層、房間號碼或姓名進行篩選。如果查詢的棟別中有多位住戶，請列出所有住戶的中文姓名、樓層與室號，並詢問使用者希望聯絡哪一位。在顯示聯絡人時，請提供該住戶的中文姓名、樓層與室號。如果有多位聯絡人，請只讀出第一位的姓名，加上「等」，並說明該棟總共有多少位住戶。`,
                            // content: `你是小區的安全助手，負責幫助使用者根據提供的建築物、樓層、房間號碼或全地址來查找住戶。以下是小區住戶的聯絡名單：${config.residents.map(resident => `${resident.棟}棟 ${resident.樓}樓 ${resident.室}室 ${resident.名字} ${resident.姓}，住址號碼：${resident.住址號碼}，全地址：${resident.住址號碼} ${resident.樓}樓 ${resident.室}室，手機號碼：${resident.手機}`).join('，')}。如果使用者查詢某個住戶，請根據他們提供的建築物、樓層、房間號碼或姓名來篩選。`,
                        },
                        // ...updatedMessages,
                        lastUserMessage,
                    ],
                    response_format: {
                        type: 'json_schema',
                        json_schema: {
                            name: 'resident_response',
                            strict: true,
                            schema: {
                                type: 'object',
                                properties: {
                                    residents: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                first_name: { type: 'string' },
                                                last_name: { type: 'string' },
                                                building: { type: 'string' },
                                                floor: { type: 'string' },
                                                room: { type: 'string' },
                                                address_number: { type: 'string' },
                                                full_address: { type: 'string', description: "Combination of address_number, floor, and room" },
                                                phone: {
                                                    type: 'string',
                                                    description: "Phone number must be in full format, including country code starting with +",
                                                },
                                            },
                                            required: ['first_name', 'last_name', 'building', 'floor', 'room', 'address_number', 'full_address', 'phone'],
                                            additionalProperties: false,
                                        },
                                    },
                                    message: { type: 'string' },
                                },
                                required: ['residents', 'message'],
                                additionalProperties: false,
                            },
                        },
                    }
                };

                // Print post content
                console.log("Post Data:", JSON.stringify(postData, null, 2));

                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    postData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${config.openAiApiKey}`,
                        },
                    }
                );




                console.log(response.data.choices[0].message.content)


                try {
                    console.log(response.data.choices[0].message.content);

                    // Parse the assistant's message content
                    const assistantMessageContent = response.data.choices[0].message.content;
                    const structuredResponse = JSON.parse(assistantMessageContent);

                    // Destructure the residents and message from the response
                    const { residents, message } = structuredResponse;

                    // Add the assistant's message to the chat
                    setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: message }]);

                    // Log and update the people options (contacts)
                    console.log('residents', residents);
                    setPeopleOptions(residents); // Update with the residents data

                    // Optionally handle text-to-speech for the message
                    await handleTextToSpeech(message);

                } catch (error) {
                    console.error('Error parsing the assistant response:', error);
                }

            }

        } catch (error) {
            console.error('獲取助手回應時出錯:', error.response ? error.response.data : error);
        } finally {
            setIsMicDisabled(false);  // Re-enable the microphone button after response or error
        }
    }, [input, messages, handleTextToSpeech, confirmedUserName]);





    // Log the updated user profile in useEffect
    useEffect(() => {
        if (userProfile.user_name) {
            console.log('Updated userProfile:', userProfile);
        }
    }, [userProfile]);


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const startListening = () => {
        console.log('Microphone button clicked!');
        setIsLoading(true); // Show the existing loading effect

        resetTranscriptionSent(); // Reset the transcription sent state


        // Open the record modal and start recording after a delay
        setTimeout(async () => {
            setIsLoading(false); // Hide loading effect after 1 second
            setShowRecordModal(true); // Show the Record modal

            // Start recording logic
            try {
                if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    console.log('AudioContext created successfully @ startlistening.');
                }

                // Ensure the AudioContext is in a running state
                if (audioContextRef.current.state === 'suspended') {
                    await audioContextRef.current.resume();
                    console.log('AudioContext resumed.');
                }

                // Request microphone access and start recording
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                console.log('Microphone access granted.');
                microphoneRef.current = stream;

                sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
                console.log('MediaStreamSource created successfully.');
                analyserRef.current = audioContextRef.current.createAnalyser();
                analyserRef.current.fftSize = 2048;
                sourceRef.current.connect(analyserRef.current);

                dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
                visualize();

                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                mediaRecorderRef.current = mediaRecorder;
                console.log('MediaRecorder initialized successfully.');
                mediaRecorder.start();
                console.log('MediaRecorder started.');

                mediaRecorder.ondataavailable = (event) => {
                    console.log('MediaRecorder data available.');
                    audioChunks.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    console.log('MediaRecorder stopped.');
                    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                    audioChunks.current = [];

                    // Send audioBlob to OpenAI for STT processing
                    await sendToOpenAI(audioBlob);
                };

                mediaRecorder.onerror = (error) => {
                    console.error('MediaRecorder error:', error);
                };
            } catch (error) {
                console.error('Error during start listening:', error);
                setMicrophoneAccessError(true);
            }
        }, 2000); // 1 second delay
    };




    useEffect(() => {
        if (transcription && !isTranscriptionSent) {
            console.log('*** AUTO　Current transcription:', transcription);
            handleSendMessage(transcription); // Automatically send the message when transcription is received
            setIsTranscriptionSent(true); // Mark transcription as sent
            // Close the record modal
            setShowRecordModal(false);
            resetTranscriptionSent(); // Reset the transcription sent state
        }
    }, [transcription, handleSendMessage, isTranscriptionSent]);



    const resetTranscriptionSent = () => {
        setTimeout(() => {
            setTranscription(''); // Clear the transcription
            setIsTranscriptionSent(false);
        }, 100); // Delay of 100ms to ensure transcription handling is complete
    };




    const reloadPage = () => {
        window.location.reload();  // Function to reload the page
    };

    const handlePersonClick = async (person) => {
        try {
            // Start loading before processing
            setIsLoading(true);



            // Stop any currently playing audio
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current = null;
            }

            // Play a sound when a person is clicked
            blipSound.play();

            // Wait for blipSound to finish playing before playing ringSound
            blipSound.onended = () => {
                ringSound.play();
            };


            // Capture the image using the webcam
            const videoElement = document.createElement('video');
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoElement.srcObject = stream;

            // Wait for the video element to be fully loaded and playing
            await new Promise((resolve, reject) => {
                videoElement.onloadedmetadata = () => {
                    videoElement.play();
                };

                videoElement.oncanplay = () => {
                    resolve();
                };

                videoElement.onerror = reject;
            });

            // Add a small delay to ensure the video is properly rendering the stream
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 100ms delay

            // Capture a screenshot
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert to data URL (image)
            const imageUrl = canvas.toDataURL('image/jpeg');

            // Stop the camera stream after capture
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());

            // Upload the image and get the imageUrl
            const uploadedImageUrl = await uploadFile(imageUrl);

            // Proceed only if the image was successfully uploaded
            if (!uploadedImageUrl) {
                console.error('Image upload failed.');
                return;
            }

            // Close the people options modal
            console.log('imageUrl', uploadedImageUrl);
            setShowPeopleOptionsModal(false);


            console.log('Selected Person:', person);

            // Generate a new room ID
            function generateRoomID() {
                const timestamp = Date.now(); // 13-digit timestamp
                const randomChars = Math.random().toString(36).substring(2, 10); // 8 random alphanumeric characters
                return `code=${timestamp}${randomChars}`;
            }

            const newRoomID = generateRoomID();
            console.log(newRoomID);
            setRoomID(newRoomID);



            // Send person data to the API endpoint with room URL and uploaded image URL
            const response = await axios.post(config.linenotify, {
                visit_id: newRoomID,
                command: "line notify",
                resident: {
                    first_name: person.first_name, // Changed to first_name
                    last_name: person.last_name,   // Added last_name
                    phone: person.phone,           // Include the phone number
                    building: person.building,     // Include building information
                    floor: person.floor,           // Include floor
                    room: person.room,             // Include room number
                    full_address: person.full_address // Include full address
                },
                room_url: `${config.videoChatBaseUrl}/concall?${newRoomID}`, // Use the base URL from config
                imageUrl: uploadedImageUrl, // Include the uploaded image URL
                user_profile: {  // Include the user profile in the payload
                    user_name: userProfile.user_name,
                    user_company: userProfile.user_company,
                    visit_purpose: userProfile.visit_purpose // Include visit purpose
                }
            });

            // Check for successful response
            if (response.status === 200) {
                console.log('API Response:', response.data); // Log the response data for debugging

                // Show the call modal after a successful response
                setShowCallModal(true); // Show the modal with the iframe
            } else {
                console.error('API response error:', response);
            }



        } catch (error) {
            console.error('Error during image capture, upload, or API call:', error);
        } finally {
            // Delay stopping loading for 3 seconds
            setTimeout(() => {
                setIsLoading(false);
            }, 5000); // 3000 milliseconds = 3 seconds
        }
    };

    const uploadFile = async (image) => {
        const eventId = 'videochat'; // Replace with your actual event ID
        const url = `https://media.dtix.tw/uploadPic/${eventId}`;
        const filename = new Date().toISOString() + '.jpg'; // Generates a unique filename for the image
        const blob = await fetch(image).then(res => res.blob()); // Converts the base64 image to a blob

        let formData = new FormData();
        formData.append('file', blob, filename); // Prepares the form data for the file upload

        setLoading(true); // Set loading state to true

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                throw new Error('檔案上傳失敗');
            }

            const data = response.data;
            console.log('檔案上傳成功:', data);

            // Return the uploaded image URL
            return data.imgUrl;

        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
            return null; // Return null if the upload fails
        } finally {
            setLoading(false); // Reset loading state
        }
    };


    useEffect(() => {
        const videoElement = document.querySelector('#startModalVideo'); // Select video element by id

        if (videoElement) {
            if (showStartModal) {
                videoElement.play(); // Play the video when the modal is visible
            } else {
                videoElement.pause(); // Pause the video when the modal is hidden
                videoElement.currentTime = 0; // Reset the video to the start
            }
        }
    }, [showStartModal]);


    // Ensure video is not playing when confirmedUserName is true
    useEffect(() => {
        if (confirmedUserName) {
            const videoElement = document.querySelector('video'); // Select the video element
            if (videoElement) {
                videoElement.pause(); // Pause the video
                videoElement.currentTime = 0; // Reset the video to the start
            }
        }
    }, [confirmedUserName]);



    function formatFirstName(firstName) {
        if (firstName.length <= 1) {
            return firstName; // If the first name has only one character, show it as is.
        }

        const lastChar = firstName[firstName.length - 1]; // Last character

        return `x ${lastChar}`;
    }


    const handleSecurityClick = (security) => {
        // Call handlePersonClick with security details
        handlePersonClick({
            first_name: security.名字,
            last_name: security.姓,
            phone: security.phone, // Assuming there's a phone field
            building: security.building, // Assuming there's a building field
            floor: security.floor, // Assuming there's a floor field
            room: security.room, // Assuming there's a room field
            full_address: `${security.building}棟 ${security.floor}樓 ${security.room}室`
        });
    };

    const handleRoomClick = (resident) => {
        // Call handlePersonClick with resident details
        handlePersonClick({
            first_name: resident.名字,
            last_name: resident.姓,
            phone: resident.手機,
            building: resident.棟,
            floor: resident.樓,
            room: resident.室,
            full_address: `${resident.棟}棟 ${resident.樓}樓 ${resident.室}室`
        });
    };



    return (
        <div className="container" style={{ width: '100%', height: '93%', maxWidth: '100%', margin: '0 auto', padding: '20px' }}>

            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background outside the box
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1100,
                    }}
                >
                    {/* White box container for the loading bars */}
                    <div>
                        {/* Dynamic bars container */}
                        <div style={{
                            display: 'flex',
                            gap: '10px', // Increased gap for larger effect
                        }}>
                            {/* Individual bars with animation */}
                            {[...Array(7)].map((_, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: '15px', // Increased width for larger bars
                                        height: '100px', // Increased height for larger effect
                                        backgroundColor: `hsl(${index * 50}, 70%, 50%)`, // Different color for each bar
                                        animation: `bounce 1.5s ease-in-out infinite`, // Longer duration for smoother animation
                                        animationDelay: `${index * 0.15}s`, // Stagger the animation start time
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {showStartModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.95)', // 90% transparent white background
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                        overflow: 'hidden',
                    }}
                >

                    {/* Background Video */}
                    <video
                        id="startModalVideo" // Add id to target this video
                        src="/idle3.webm"
                        autoPlay
                        loop
                        muted
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 'auto', // Maintain aspect ratio
                            height: 'auto', // Maintain aspect ratio
                            maxWidth: '140%', // Max width 30% of the screen width
                            maxHeight: '140%', // Max height 30% of the screen height
                            objectFit: 'contain', // Contain within the bounding box
                            zIndex: 1,
                        }}
                    />

                    {/* Centered Button */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2, // Ensure it's on top of the video
                            backgroundColor: 'transparent',
                            transform: 'translateY(30%)', // Move 10% lower
                        }}
                    >


                        <button
                            className={`btn btn-microphone ${isListening ? 'listening' : ''}`}
                            onClick={() => {
                                handleStart();
                                // Stop the video
                                const videoElement = document.querySelector('video');
                                if (videoElement) {
                                    videoElement.pause();
                                    videoElement.currentTime = 0; // Reset to start if needed
                                }
                            }}
                            style={{
                                width: '240px', // 1.5 times larger than the original 160px
                                height: '240px', // 1.5 times larger than the original 160px
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: isMicDisabled ? 'not-allowed' : 'pointer',
                                opacity: isMicDisabled ? 0.5 : 1,
                                backgroundColor: '#007bff',
                                color: '#fff',
                            }}
                            disabled={isMicDisabled}
                        >
                            <FaPlay size={100} /> {/* Start icon with increased size */}
                        </button>

                        <h2
                            style={{
                                background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.9))', // Gradient background for a subtle effect
                                color: '#333', // Darker text color for contrast
                                padding: '20px 40px', // Increased padding for a more spacious look
                                borderRadius: '12px', // Smoother, slightly larger border radius
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Slightly stronger shadow for better depth
                                textAlign: 'center',
                                marginTop: '40px', // Reduced top margin for better spacing
                                marginBottom: '30px', // Increased bottom margin for a balanced layout
                                transition: 'all 0.3s ease-in-out', // Smooth transitions for hover effects
                                fontFamily: "'Noto Sans TC', sans-serif", // Use a clean, modern font for Traditional Chinese
                                fontWeight: 'bold', // Bold text for emphasis
                                letterSpacing: '1px', // Slightly spaced letters for readability
                            }}
                        >
                            鴻翊大觀社區
                            <br />AI門禁管理系統
                            <br />請按開始
                        </h2>


                    </div>
                </div>
            )}


            {/* OCR Modal */}
            {showOcrModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <div
                        style={{
                            width: '90%',
                            maxWidth: '800px',
                            backgroundColor: '#fff',
                            borderRadius: '12px',
                            padding: '20px',
                            position: 'relative',
                        }}
                    >
                        <button
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                fontSize: '5rem',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                zIndex: 1100, // Ensure the button is above other elements
                                padding: '20px', // Increase the clickable area
                            }}
                            onClick={() => setShowOcrModal(false)}
                        >
                            &times;
                        </button>


                        {/* Render Ocr component */}
                        <Ocr />
                    </div>
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '20px', position: 'relative' }}>
                {/* Left half for user's name */}
                {confirmedUserName && (
                    <h2 style={{
                        position: 'absolute',  // Absolute positioning to keep it aligned in the left half
                        left: '10%',           // Align in the left half
                        fontSize: '1.6rem',    // Slightly larger font size for visibility
                        fontWeight: '500',     // Medium weight for a bit more emphasis
                        color: '#5A4A00',      // Darker yellow-brown text color for better contrast
                        background: 'linear-gradient(135deg, #FFE600, #FFDD44)', // Bright yellow gradient background
                        padding: '10px 20px',  // Slightly increased padding for comfort
                        borderRadius: '12px',  // More rounded corners for a friendlier look
                        border: '2px solid #FFC300', // Stronger border with a golden yellow
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for depth
                        textShadow: '1px 1px 4px rgba(255, 255, 255, 0.5)', // Light white shadow for text clarity
                        transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                    }}>
                        您好, {confirmedUserName}!
                    </h2>
                )}


                {/* Centered company logo */}
                <img src="/datavan_icon.jpg" alt="Datavan Icon" style={{ maxWidth: '180px', marginRight: '20px' }} />
            </div>


            {/* Reload Button with Absolute Position */}
            <button
                onClick={reloadPage}  // Add reload function
                style={{
                    position: 'absolute',
                    top: '20px',  // Position the button at the top
                    right: '20px',  // Position the button to the right
                    backgroundColor: 'darkgrey',  // Dark grey background
                    color: 'white',  // White icon
                    border: 'none',
                    borderRadius: '50%',  // Circular shape
                    padding: '20px',  // Increase padding for larger size
                    cursor: 'pointer',
                    fontSize: '2rem',  // Increase font size for icon size
                    width: '60px',  // Set fixed width to ensure circular shape
                    height: '60px',  // Set fixed height to ensure circular shape
                    display: 'flex',  // Center icon inside button
                    justifyContent: 'center',  // Center icon horizontally
                    alignItems: 'center',  // Center icon vertically
                    zIndex: 1000  // Ensure button is above other elements
                }}
            >
                <FaSyncAlt />  {/* Reload icon */}
            </button>

            <div className="responsive-container" style={{ display: 'flex', justifyContent: 'space-between', height: '700px', width: '100%' }}>

                {/* Chat Box on the Left */}
                <div className="chat-box mb-3" ref={chatBoxRef} style={{ overflowY: 'auto', flex: '0 0 35%', marginRight: '20px', height: '90%' }}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.role}`}>
                            <div className="bubble">
                                {msg.content}
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{
                    flex: '0 0 15%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '70px 0'
                }}>

                    {/* Enlarged OCR Button with ID detection icon */}
                    <button
                        className="btn btn-secondary"
                        style={{
                            marginBottom: '140px', // Increase margin to move it higher
                            fontSize: '2rem', // Reduce font size for smaller text
                            padding: '20px 40px', // Enlarge button padding
                            display: 'flex',
                            flexDirection: 'column', // Stack text and icon vertically
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}
                        onClick={() => setShowOcrModal(true)}
                    >
                        <FaIdCard className="mb-2" size={30} /> {/* Add FontAwesome ID card icon */}
                        <span>證照</span>
                        <span>辨識</span>  {/* Break text into two lines */}
                    </button>


                    {/* Microphone Button */}
                    <button
                        className={`btn btn-microphone ${isListening ? 'listening' : ''}`}
                        onMouseDown={handleMicrophoneClick}
                        onTouchStart={handleMicrophoneClick}
                        style={{
                            width: '180px',
                            height: '180px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: isMicDisabled ? 'not-allowed' : 'pointer',
                            opacity: isMicDisabled ? 0.5 : 1,
                        }}
                        disabled={isMicDisabled}
                    >
                        <div style={{ ...highlightStyles, top: '0', left: '-120px' }}>
                            <div style={tooltipStyles1}>
                                {confirmedUserName
                                    ? '可按麥克風詢問要找的人'
                                    : '按麥克風告知您的名字及公司'}
                                <div style={arrowStyles1}></div>
                            </div>
                        </div>
                        {isListening ? <RiSpeakFill size={78} /> : <FaMicrophone size={78} />}
                    </button>
                </div>





                <div
                    className="responsive-container"
                    style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '75vh' }} // Set width to 40%
                >
                    {/* Contacts Section */}
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#f0f0f0',
                            padding: '20px',
                            borderRadius: '8px',
                            flex: 1, // Allow it to take the remaining space
                            overflowY: 'auto'
                        }}
                    >
                        {/* Pass the handlers as props */}
                        <Contacts
                            onSecurityClick={handleSecurityClick}
                            onRoomClick={handleRoomClick}
                        />
                    </div>
                </div>



            </div>

            {/* Conditionally render the input section */}
            {showInputSection && (
                <div className="input-group" style={{ fontSize: '1.25rem', height: '6vh', width: '1000px' }}>
                    <input
                        type="text"
                        className="form-control"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="電腦打字輸入..."
                        style={{
                            fontSize: '3rem',
                            padding: '10px',
                            border: '2px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: 'none'
                        }}
                    />
                    <button
                        className="btn btn-primary"
                        onClick={() => handleSendMessage(input)}
                        style={{ fontSize: '2rem', padding: '10px 20px' }}
                    >
                        發送
                    </button>
                </div>
            )}

            {/* Your existing modal and camera code here */}
            {showRecordModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a slight dark overlay
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for the card effect
                            padding: '30px',
                            textAlign: 'center',
                            width: '80%',
                            maxWidth: '600px',
                        }}
                    >

                        {/* Large "X" button to close the modal */}
                        <button
                            onClick={() => {
                                stopSound.play().then(async () => {
                                    // Call handleStop to stop all ongoing processes
                                    await handleStop();

                                    // Reset or clear any relevant state or data to avoid sending anything to OpenAI
                                    setTranscription('');
                                    setIsTranscriptionSent(false);
                                    setInput('');  // Clear input to ensure no further action
                                    setPeopleOptions([]); // Clear any options shown in the modal

                                    // Close modals if needed
                                    setShowRecordModal(false);
                                    setShowPeopleOptionsModal(false);
                                    setShowModal(false);
                                }).catch(err => console.error('Error playing stop sound:', err));
                            }}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                background: 'none',
                                border: 'none',
                                fontSize: '4rem', // Large size for "X"
                                cursor: 'pointer',
                                color: 'black',
                            }}
                            aria-label="Close"
                        >
                            &times;
                        </button>


                        <ul
                            style={{
                                textAlign: 'left', // Align text to the left
                                marginBottom: '20px',
                                listStyleType: 'none',
                                padding: 0,
                                fontFamily: 'Verdana, sans-serif',
                                fontSize: '1.1rem',
                                maxWidth: '80%', // Limit width for better layout
                                margin: '0 auto', // Center the list
                            }}
                        >
                            {(confirmedUserName ? [
                                '我要找224號18樓-3的張先生',
                                '我要A棟3樓之3的,張先生',
                                '我要C棟的李小龍先生'
                            ] : [
                                '我叫李小龍,是永慶房屋銷售專員,我帶客戶來看房子',
                                '我姓葉,是UberEats的送貨員,來送餐',
                                '我姓翁,我來拜訪朋友'
                            ]).map((text, index) => (
                                <li
                                    key={index}
                                    style={{
                                        padding: '16px 20px',
                                        marginBottom: '10px',
                                        backgroundColor: '#f0f0f0', // Change background color to light grey
                                        color: '#333', // Darker text color for better contrast
                                        borderLeft: '6px solid #007bff',
                                        borderRadius: '6px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                                        userSelect: 'none', // Disable text selection
                                        pointerEvents: 'none', // Disable pointer events to make it readonly
                                        opacity: 0.9, // Slightly fade the item to indicate readonly
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = '#e0e0e0') // Slightly darker grey on hover
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = '#f0f0f0') // Revert to light grey
                                    }
                                >
                                    {text}
                                </li>
                            ))}
                        </ul>




                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <canvas
                                ref={canvasRef}
                                width="300"
                                height="200"
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                }}
                            />
                        </div>

                        <h3 style={{ marginBottom: '40px' }}>請說話...結束按下面停止鍵</h3>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <button
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        handleStop();
                                    }).catch(err => console.error('Error playing stop sound:', err));
                                }}
                                style={{
                                    width: '130px',
                                    height: '130px',
                                    fontSize: '4rem',
                                    borderRadius: '50%',
                                    backgroundColor: '#000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                <div
                                    style={{
                                        width: '50%',
                                        height: '50%',
                                        backgroundColor: 'white',
                                    }}
                                />
                            </button>

                        </div>
                    </div>
                </div>
            )}



            {microphoneAccessError && (
                <div
                    style={{
                        position: 'fixed',
                        top: '20%',
                        left: '50%',
                        transform: 'translate(-50%, -20%)',
                        backgroundColor: '#ffdddd',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                        zIndex: 1001,
                        textAlign: 'center',
                    }}
                >
                    <p style={{ color: '#d8000c', fontSize: '1.2rem' }}>無法存取麥克風。請檢查您的瀏覽器權限設置。</p>
                    <button
                        onClick={onClose}
                        style={{
                            backgroundColor: '#d8000c',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            border: 'none',
                        }}
                    >
                        關閉
                    </button>
                </div>
            )}

            {showPeopleOptionsModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for responsiveness
                            maxWidth: '1000px',
                            height: '80vh', // Adjust height for responsiveness
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <h1>選擇聯絡人通話</h1>

                            {loading && (
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>檔案上傳中，請稍候...</p>
                                </div>
                            )}

                            {responseData && (
                                <div className="d-flex justify-content-center mt-4">
                                    <div>
                                        <h3>AI 分析結果</h3>
                                        <p>{responseData.someField}</p>
                                    </div>
                                </div>
                            )}

                            <video
                                id="cameraVideo1"
                                style={{
                                    width: '120px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    marginLeft: '20px',
                                }}
                                autoPlay
                            ></video>

                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef1);
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '4rem',
                                    cursor: 'pointer',
                                    color: 'black',
                                }}
                            >
                                &times;
                            </button>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                overflowY: 'auto',
                                marginTop: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px', // Adjusted gap for responsiveness
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {peopleOptions.map((person, index) => {
                                const colorClasses = ['btn-primary', 'btn-success'];
                                const randomColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];

                                return (
                                    <button
                                        key={index}
                                        className={`btn ${randomColorClass} text-white d-flex align-items-center justify-content-center`}
                                        onClick={() => handlePersonClick(person)}
                                        style={{
                                            width: '300px', // Adjusted width for responsiveness
                                            height: '80px', // Adjusted height for responsiveness
                                            fontSize: '1.5rem',
                                            margin: '10px',
                                            borderRadius: '8px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span>{person.last_name} {formatFirstName(person.first_name)}</span>

                                        <span style={{ fontSize: '1rem', opacity: 0.8 }}>{person.building}{person.floor}-{person.room} ({person.address_number}號)</span>
                                    </button>
                                );
                            })}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    // Play the stop sound first
                                    stopSound.play()
                                        .then(async () => {
                                            // After the sound has finished playing, call handleStop and close the modal
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef1);
                                        })
                                        .catch(err => console.error('Error playing stop sound:', err));
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '20px 40px', // Adjusted padding for button
                                    border: 'none',
                                    borderRadius: '12px',
                                    cursor: 'pointer',
                                    fontSize: '1.5rem',
                                }}
                            >
                                關閉
                            </button>

                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%', // Adjust for full-screen modal on mobile
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for smaller screens
                            maxWidth: '900px',
                            height: 'auto', // Auto-adjust height
                            maxHeight: '80vh', // Constrain height for larger screens
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef2);
                                            window.location.reload();
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '5rem',
                                    cursor: 'pointer',
                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div style={{ flex: 1, overflowY: 'auto', marginTop: '10px' }}>
                            <Room match={{ params: { roomID } }} />
                        </div>
                        <div className="transcription-display" style={{ marginTop: '20px', color: '#333333' }}>
                            {transcription && <p style={{ color: 'white' }}>{transcription}</p>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef2);
                                            window.location.reload();
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showCallModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <div
                        style={{
                            width: '90%',
                            maxWidth: '1200px', // Adjusted for better view
                            height: 'auto',
                            maxHeight: '90vh', // Allow maximum height for larger screens
                            backgroundColor: '#fff',
                            borderRadius: '12px', // Smoother border radius
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Subtle box-shadow for depth
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <button
                            onClick={toggleCallModal}
                            style={{
                                position: 'absolute',
                                top: '20px', // Adjusted for better spacing
                                right: '20px',
                                background: 'none',
                                border: 'none',
                                fontSize: '6rem', // Adjusted size for better UI
                                cursor: 'pointer',
                                color: '#ffffff', // Default color is now white
                                transition: 'color 0.2s ease', // Smooth transition for color change
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.color = '#d3d3d3')} // Change color to light grey on hover
                            onMouseOut={(e) => (e.currentTarget.style.color = '#ffffff')} // Revert color to white on mouse out
                        >
                            &times;
                        </button>

                        {/* Embed the Whereby iframe */}
                        <div style={{ padding: '20px', textAlign: 'center' }}> {/* Padding around the iframe */}
                            <p style={{ color: '#4f4f4f' }}>剩餘時間: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}</p>

                            <iframe
                                src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                                allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                                style={{
                                    width: '100%', // Make iframe responsive and take full width
                                    height: '75vh', // Make the height 75% of the viewport height
                                    border: 'none', // Remove iframe border
                                    borderRadius: '8px', // Smoother iframe border radius
                                }}
                                frameBorder="0"
                                title="Video Call"
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Inject the keyframes for the flashing animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
    @keyframes flash {
        0%, 100% { opacity: 1; transform: scale(1); }
        50% { opacity: 0.8; transform: scale(1.05); }
    }
`, styleSheet.cssRules.length);

export default Help;
